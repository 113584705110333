import React, { useEffect } from 'react';
import './TechnologiesAndTools.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import your SVG icons here
import { ReactComponent as GoogleAnalyticsIcon } from './assets/images/logos/google-analytics.svg';
import { ReactComponent as GoogleTagManagerIcon } from './assets/images/logos/google-tag-manager.svg';
import { ReactComponent as LookerStudioIcon } from './assets/images/logos/looker-studio.svg';
import { ReactComponent as GoogleSearchConsoleIcon } from './assets/images/logos/google-search-console.svg';
import { ReactComponent as GoogleAds } from './assets/images/logos/google-ads.svg';
import { ReactComponent as HTML5Icon } from './assets/images/logos/html5.svg';
import { ReactComponent as CSS3Icon } from './assets/images/logos/css3.svg';
import { ReactComponent as BootstrapIcon } from './assets/images/logos/bootstrap.svg';
import { ReactComponent as JavascriptIcon } from './assets/images/logos/javascript.svg';
import { ReactComponent as PHPIcon } from './assets/images/logos/php.svg';
import { ReactComponent as WordpressIcon } from './assets/images/logos/wordpress.svg';
import { ReactComponent as GitHubIcon } from './assets/images/logos/github-skill.svg';
import { ReactComponent as VSCodeIcon } from './assets/images/logos/vscode.svg';
import { ReactComponent as CPlusPlusIcon } from './assets/images/logos/cplusplus.svg';
import { ReactComponent as JavaIcon } from './assets/images/logos/java.svg';
import { ReactComponent as PythonIcon } from './assets/images/logos/python.svg';
import { ReactComponent as FigmaIcon } from './assets/images/logos/figma.svg';
import { ReactComponent as PhotoshopIcon } from './assets/images/logos/photoshop.svg';
import { ReactComponent as MiroIcon } from './assets/images/logos/miro.svg';
import { ReactComponent as CanvaIcon } from './assets/images/logos/canva.svg';

const TechnologyIcon = ({ Icon, index }) => (
  <div 
    className="technology-icon" 
    data-aos="zoom-in" 
    data-aos-delay={100 * index}
  >
    <Icon className="tech-svg-icon" />
  </div>
);

const TechnologiesAndTools = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 200
    });
  }, []);

  const technologies = [
    { Icon: GoogleAnalyticsIcon },
    { Icon: GoogleTagManagerIcon },
    { Icon: LookerStudioIcon },
    { Icon: GoogleSearchConsoleIcon },
    { Icon: GoogleAds },
    { Icon: HTML5Icon },
    { Icon: CSS3Icon },
    { Icon: BootstrapIcon },
    { Icon: JavascriptIcon },
    { Icon: PHPIcon },
    { Icon: WordpressIcon },
    { Icon: GitHubIcon },
    { Icon: VSCodeIcon },
    { Icon: CPlusPlusIcon },
    { Icon: JavaIcon },
    { Icon: PythonIcon },
    { Icon: FigmaIcon },
    { Icon: MiroIcon },
    { Icon: PhotoshopIcon },
    { Icon: CanvaIcon },
  ];

  return (
    <div className="technologies-tools-section">
      <h2 className="section-title">Technologies & Tools</h2>
      <p className="section-description">Here are the tools and technologies I've used in my web development journey.</p>
      <div className="technologies-grid">
        {technologies.map((tech, index) => (
          <TechnologyIcon key={index} Icon={tech.Icon} index={index} />
        ))}
      </div>
    </div>
  );
};

export default TechnologiesAndTools;