import React, { useEffect, useState } from 'react';
import './AboutSection.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import your SVG icons here
import { ReactComponent as WebDevelopmentIcon } from './assets/images/logos/web_development.svg';
import { ReactComponent as WebDesignIcon } from './assets/images/logos/web_design.svg';
import { ReactComponent as AnalyticsIcon } from './assets/images/logos/analytics.svg';
import { ReactComponent as TrafficAnalysisIcon } from './assets/images/logos/traffic_analysis.svg';


const ServiceBox = ({ Icon, title }) => {
  const [transform, setTransform] = useState('');

  const handleMouseMove = (e) => {
    const box = e.currentTarget;
    const boxRect = box.getBoundingClientRect();
    const mouseX = e.clientX - boxRect.left;
    const mouseY = e.clientY - boxRect.top;
    const rotateY = (mouseX / boxRect.width - 0.5) * 20;
    const rotateX = (0.5 - mouseY / boxRect.height) * 20;
    setTransform(`perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(1.05, 1.05, 1.05)`);
  };

  const handleMouseLeave = () => {
    setTransform('');
  };

  return (
    <div 
      className="service-box" 
      onMouseMove={handleMouseMove} 
      onMouseLeave={handleMouseLeave}
      style={{ transform }}
    >
      <Icon className="service-icon" />
      <h3 className="service-title">{title}</h3>
    </div>
  );
};

const AboutSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 200
    });
  }, []);

  return (
    <section className="about-section">
      <div className="content-wrapper">
        <h2 className="about-subtitle" data-aos="fade-right" data-aos-delay="100">ABOUT ME</h2>
        <h1 className="about-title" data-aos="fade-right" data-aos-delay="200">What do I do?</h1>
        <p className="about-description" data-aos="fade-up" data-aos-delay="300">
          Hey there! My web dev journey kicked off in junior high, and it's been quite the
          adventure since. Now, I'm here to turn your digital dreams into reality. Below,
          you'll find the services I offer, but don't think that's all – I'm always eager to
          learn and tackle new challenges. Whether you need a sleek website or a
          complex web app, I'm your friendly neighborhood developer, ready to bring
          your ideas to life. Let's create something awesome together!
        </p>
        <div className="services-grid">
          <div data-aos="fade-up" data-aos-delay="400">
            <ServiceBox Icon={WebDevelopmentIcon} title="Web Development" />
          </div>
          <div data-aos="fade-up" data-aos-delay="500">
            <ServiceBox Icon={WebDesignIcon} title="Web Design" />
          </div>
          <div data-aos="fade-up" data-aos-delay="600">
            <ServiceBox Icon={AnalyticsIcon} title="Analytics" />
          </div>
          <div data-aos="fade-up" data-aos-delay="700">
            <ServiceBox Icon={TrafficAnalysisIcon} title="Traffic Analysis" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;