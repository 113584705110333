import React from 'react';
import './StatsAndSocials.css';

// Import SVG files as React components
import { ReactComponent as GithubIcon } from './assets/images/logos/linkedin.svg';
import { ReactComponent as InstagramIcon } from './assets/images/logos/github.svg';
import { ReactComponent as LinkedinIcon } from './assets/images/logos/facebook.svg';
import { ReactComponent as FacebookIcon } from './assets/images/logos/instagram.svg';

const StatsAndSocials = () => {
  return (
    <div className="stats-and-socials animate__animated animate__fadeInUp animate__delay-2s">
      <div className="container">
        <div className="stats">
          <div className="stat-item">
            <span className="stat-value">3+</span>
            <p className="stat-label">Years of<br />Experience</p>
          </div>
          <div className="stat-item">
            <span className="stat-value">11</span>
            <p className="stat-label">Total Projects<br />Contributed</p>
          </div>
        </div>
        <div className="socials">
          <span className="social-label">Let's Stay Connected</span>
          <div className="social-icons">
            <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer">
              <GithubIcon className="icon" />
            </a>
            <a href="https://instagram.com/yourusername" target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="icon" />
            </a>
            <a href="https://linkedin.com/in/yourusername" target="_blank" rel="noopener noreferrer">
              <LinkedinIcon className="icon" />
            </a>
            <a href="https://facebook.com/yourusername" target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsAndSocials;