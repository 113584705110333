import React, { useState } from 'react';
import './Navbar.css';
import densorianologov2 from './assets/images/logos/den-soriano-logo-v2.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar animate__animated animate__fadeInDown">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={densorianologov2} alt="den-soriano-logo-v2" />
        </div>
        <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <a href="#about">About</a>
          <a href="#experience">Experience</a>
          <a href="#portfolio">Portfolio</a>
          <button className="navbar-cta">Connect with me</button>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;