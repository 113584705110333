import React from 'react';
import './Footer.css';
import footerlogo from './assets/images/logos/den-soriano-logo-v2.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={footerlogo} alt="Den Soriano Logo" className="footer-logo" />
        <p className="footer-text">
          Copyright © {new Date().getFullYear()}. All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;