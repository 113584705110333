import React from 'react';
import "./App.css";
import GridBackground from './GridBackground';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import TypeIt from "typeit-react";
import StatsAndSocials from './StatsAndSocials';
import AboutSection from './AboutSection';
import TechnologiesAndTools from './TechnologiesAndTools';
import Footer from './Footer'; // Import the new Footer component


function App() {
  return (
    <GridBackground>
      <div className="app">
        <Navbar />
        <HeroSection />
        <StatsAndSocials />
        <AboutSection />
        <TechnologiesAndTools />
        <Footer /> 
      </div>
    </GridBackground>
  );
}

export default App;