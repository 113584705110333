import React from 'react';
import './GridBackground.css';

const Background = ({ children }) => {
  return (
    <div className="background-container">
      <div className="gradient-background"></div>
      <div className="background-content">
        {children}
      </div>
    </div>
  );
};

export default Background;