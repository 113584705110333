import React from 'react';
import './HeroSection.css';
import { ReactComponent as DenSorianoSVG } from './assets/images/den-soriano-pixar-ai.svg';
import TypeIt from "typeit-react";

const HeroSection = () => {
  const dataText = "data-driven insights";  // Correct spacing added here

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1 className="animate__animated animate__fadeInLeft">
          Crafting digital experiences with{' '}
          <span className="code-word">
            <TypeIt
              options={{
                strings: ["&lt;/code&gt;"],
                speed: 150,
                waitUntilVisible: true,
                cursor: false,
              }}
            />
          </span>,{' '}
          <span className="creativity-word">creativity</span>, and{' '}
          <span className="data-word">📊
            {dataText.split('').map((char, index) => (
              <span 
                key={index} 
                className="chart-letter" 
                style={{animationDelay: `${1.5 + index * 0.12}s`}}
              >
                {char === ' ' ? '\u00A0' : char}
              </span>
            ))}
          </span>.
        </h1>
        <p className="animate__animated animate__fadeInUp">
          Greetings, fellow earthling! I'm Den, your<br />friendly neighborhood Web Developer.
        </p>
        <button className="cta-button animate__animated animate__bounceInUp">Connect with me</button>
      </div>
      <div className="hero-image animate__animated animate__fadeIn">
        <DenSorianoSVG />
      </div>
    </section>
  );
};

export default HeroSection;